<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div class="logo">
              <img src="../assets/logo.png" alt="Nvoko Logo" />
            </div>
            <div class="left">
              <span class="flex items-center justify-start gap-2">
                <img class="w-4 h-4" v-if="isNvoko" src="../assets/logo.png" alt="Nvoko Logo" />
                {{ userDetails.email }}
              </span>
              <div @click="logout">
                <LogoutIcon :dark="true" />
              </div>
            </div>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body">
            <div class="progressbar">
              <div class="line" :style="{ width: progressPercent + '%' }"></div>
            </div>
            <div class="content">
              <div class="step-1" v-if="currentStep === 'is_artist'">
                <h3 class="title">
                  Are you an artist, producer, or engineer?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-5" v-if="currentStep === 'is_represented'">
                <h3 class="title">
                  Who will be entering into music contracts?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">Me</span>
                      <span class="sub">(Signing as an individual)</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">A Company</span>
                      <span class="sub">
                        (Such as a label, loan-out, or production company)
                      </span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-2" v-if="currentStep === 'is_representative'">
                <h3 class="title">
                  Do you represent a company? (Such as a label or production
                  company)
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-2" v-if="currentStep === 'recording_agreement_label'">
                <h3 class="title">
                  Do you have a recording agreement with a label?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-2" v-if="currentStep === 'owns_company'">
                <h3 class="title">
                  Do you own the music company?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-2" v-if="currentStep === 'recording_agreement'">
                <h3 class="title">
                  Do you have a recording agreement between you and the company
                  you own?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-2" v-if="currentStep === 'recording_agreement_third_party'">
                <h3 class="title">
                  Do you have a recording agreement between the company you own
                  and a third-party label?
                </h3>
                <div class="radiobuttons dark">
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="true" />
                      <span class="text">Yes</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="radio-group">
                    <label class="radio">
                      <input v-model="answer.value" type="radio" :value="false" />
                      <span class="text">No</span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="step-3" v-if="currentStep === 'artist_input'">
                <AddArtistForm v-model="answer" not-signed />
              </div>
              <div class="step-3" v-if="currentStep === 'final_step_artist_input_independent'">
                <AddArtistForm no-user-info v-model="answer" not-signed />
              </div>
              <div class="step-4" v-if="currentStep === 'company_input_profile'">
                <h4>COMPANY PROFILE</h4>
                <div class="inputs">
                  <div class="form-group">
                    <input v-model="answer.name" type="text" class="text-input dark"
                      placeholder="Label or Loan-out Company Name*" />
                  </div>
                  <div class="form-group">
                    <AddressInput v-model="answer.address" title="Label or Loan-out Company Address*" />
                  </div>
                </div>
              </div>
              <div class="step-4" v-if="currentStep === 'final_step_company_input_people'">
                <div class="people">
                  <h4>COMPANY REPRESENTATIVES</h4>
                  <div class="row" v-for="({ firstName, lastName, me },
                  i) in answer.representatives" :key="i">
                    <template v-if="firstName && lastName">
                      <div class="initials">
                        {{ [firstName, lastName] | initials }}
                      </div>
                      <span>{{ firstName + " " + lastName }}</span>
                      <span class="delete" v-if="!me" @click="deleteRepresentative(i)">
                        &#x2715;
                      </span>
                    </template>
                  </div>
                  <div class="row add" @click="openAddRepresentativeModal">
                    <button class="add-button">+</button>
                    <span>Add Another Representative</span>
                  </div>
                </div>
                <div class="people">
                  <h4>ARTISTS AND PRODUCERS</h4>
                  <div class="row" v-for="({ talentName, me }, i) in answer.artists" :key="i">
                    <template v-if="talentName">
                      <div class="initials">
                        {{ [talentName] | initials }}
                      </div>
                      <span>{{ talentName }}</span>
                      <span class="delete" v-if="!me" @click="deleteArtist(i)">
                        &#x2715;
                      </span>
                    </template>
                  </div>
                  <div class="row add" @click="openAddArtistModal">
                    <button class="add-button">+</button>
                    <span>Add Artist / Producer</span>
                  </div>
                </div>
              </div>
              <div class="step-6" v-if="currentStep === 'final_billing'">
                <p class="title">
                  Choose your plan and start securing your creative rights
                </p>
                <div class="plans">
                  <div class="plan" :class="{ active: plan === 'base' }" @click="plan = 'base'">
                    <h2>Nvoko Base</h2>
                    <p class="price">
                      $49/Song
                    </p>
                    <p class="description">
                      <span>
                        Contracts covering splits, rights, and more.
                      </span>
                    </p>
                  </div>
                  <div class="plan" :class="{ active: plan === 'pro' }" @click="plan = 'pro'">
                    <h2>Nvoko Pro</h2>
                    <p class="price">
                      $299/year
                    </p>
                    <p class="description">
                      <span>
                        Unlimited catalog protection.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="step-7" v-if="currentStep === 'final_ineligible'">
                <p>
                  We appreciate your interest, but we currently only allow
                  unsigned artists, labels, or loan-out representatives to
                  register.
                </p>
              </div>
              <div class="step-7" v-if="currentStep === 'final_label_instead'">
                <p>
                  We appreciate your interest, but your label needs to sign you
                  up.
                </p>
              </div>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <div class="buttons">
              <button v-if="activeSteps.length > 1 && isNvoko" class="primary outline dark" @click="previous">
                Previous
              </button>
              <button v-if="!currentStep.includes('final')" :disabled="!stepValid" class="primary" @click="next">
                Next
              </button>
              <template v-else>
                <button v-if="currentStep.includes('step')" :disabled="!stepValid || submitting" class="primary"
                  @click="submit">
                  {{ submitting ? "Finishing..." : "Finish!" }}
                </button>
                <button class="primary" v-else @click="logout">
                  Log out
                </button>
              </template>
            </div>
          </slot>
        </div>
      </div>
      <AddArtist v-if="addingArtist" v-model="answer.artists[answer.artists.length - 1]" @close="closeAddArtistModal"
        :valid="validate(1)" not-signed />
      <AddRepresentative v-if="addingRepresentative" v-model="answer.representatives[answer.representatives.length - 1]"
        @close="closeAddRepresentative" :valid="validate(2)" />
      <AddCard v-if="addingCard" :is-onboarding="true" @close="closeAddCard" />
    </div>
  </transition>
</template>

<script>
import LogoutIcon from "@/assets/icons/Logout.vue";
import AddArtistForm from "@/components/AddArtistForm.vue";
import AddressInput from "@/components/AddressInput.vue";
import AddArtist from "@/components/modals/AddArtist.vue";
import AddRepresentative from "@/components/modals/AddRepresentative.vue";
import AddCard from "@/components/modals/AddCard.vue";

import ONBOARDING from "@/api/mutations/ONBOARDING.gql";
import ADD_CONNECTIONS from "@/api/mutations/ADD_CONNECTIONS.gql";
import SYNC_CONTACTS from "@/api/mutations/SYNC_CONTACTS.gql";
import SUBSCRIBE from "@/api/mutations/SUBSCRIBE.gql";

export default {
  name: "Onboarding",
  components: {
    LogoutIcon,
    AddArtistForm,
    AddArtist,
    AddRepresentative,
    AddCard,
    AddressInput
  },
  data() {
    return {
      addingArtist: false,
      addingRepresentative: false,
      addingCard: false,
      plan: null,
      card: null,
      submitting: false,
      answer: {},
      answers: [],
      activeSteps: ["is_artist"],
      steps: {
        is_artist: {
          yes: "is_represented",
          no: "is_representative"
        },
        is_represented: {
          yes: "owns_company",
          no: "recording_agreement_label"
        },
        is_representative: {
          yes: "recording_agreement_third_party",
          no: "final_ineligible"
        },
        recording_agreement_label: {
          yes: "final_label_instead",
          no: "final_step_artist_input_independent"
        },
        owns_company: {
          yes: "recording_agreement",
          no: "final_label_instead"
        },
        recording_agreement: {
          yes: "recording_agreement_third_party",
          no: "recording_agreement_third_party"
        },
        recording_agreement_third_party: {
          yes: "artist_input",
          no: "artist_input"
        },
        artist_input: {
          yes: "company_input_profile"
        },
        company_input_profile: {
          yes: "final_step_company_input_people"
        }
      }
    };
  },
  watch: {
    plan() {
      this.addingCard = !this.card;
    }
  },
  computed: {
    userDetails() {
      const user = this.$store.getters["account/getUser"];
      return {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        me: true
      };
    },
    progressPercent() {
      const perc = (this.step / this.steps) * 100;
      return perc > 100 ? 100 : perc;
    },
    allAnswers() {
      const answers = {};
      this.activeSteps.forEach((el, i) => {
        answers[el] = this.answers[i];
      });
      return answers;
    },
    currentStep() {
      return this.activeSteps[this.activeSteps.length - 1];
    },
    stepValid() {
      if (this.currentStep === "artist_input") {
        return (
          this.answer.firstName &&
          this.answer.lastName &&
          this.answer.email &&
          this.answer.talentName &&
          this.answer.PROAffiliation &&
          this.answer.songwriterIPINo &&
          this.answer.address
        );
      }

      if (this.currentStep === "final_step_artist_input_independent") {
        return (
          this.answer.talentName &&
          this.answer.PROAffiliation &&
          this.answer.songwriterIPINo &&
          this.answer.address
        );
      }

      if (this.currentStep === "company_input_profile") {
        return !!this.answer.name && !!this.answer.address;
      }

      if (this.currentStep === "final_step_company_input_people") {
        return (
          this.answer.representatives.length &&
          this.answer.artists.length &&
          this.answer.representatives.every(
            el => el.firstName && el.lastName && el.email
          ) &&
          this.answer.artists.every(
            el =>
              el.firstName &&
              el.lastName &&
              el.email &&
              el.talentName &&
              el.PROAffiliation &&
              el.songwriterIPINo &&
              el.address
          )
        );
      }

      if (this.currentStep === "final_billing") return !!this.card;

      return typeof this.answer.value === "boolean";
    },
    isNvoko() {
      return this.userDetails.email.includes("@nvoko.com");
    }
  },
  filters: {
    initials(names) {
      return names
        .map(el => {
          if (!el) return "";
          return el.charAt(0).toUpperCase();
        })
        .join("");
    }
  },
  methods: {
    validate(el) {
      let resp = false;
      switch (el) {
        case 1:
          resp = this.answer.artists.every(el => {
            return (
              el.firstName &&
              el.lastName &&
              el.email &&
              el.talentName &&
              el.PROAffiliation &&
              el.songwriterIPINo &&
              el.address
              // this.validateIPI(el.songwriterIPINo) &&
              // this.validateIPI(el.publisherIPINo)
            );
          });
          break;
        case 2:
          resp = this.answer.representatives.every(
            el => el.firstName && el.lastName && el.email
          );
          break;
        default:
          resp = false;
      }
      return resp;
    },
    validateIPI(val = "") {
      if (!val) return true;
      if (val.length !== 11) return false;

      const x = val.split("");
      const digits = x.splice(0, 9);

      let sum = 0;
      for (const [i, digit] of digits.entries()) {
        sum += Number(digit) * (10 - i);
      }

      sum = sum % 101;
      if (sum !== 0) sum = (101 - sum) % 100;
      sum = sum < 10 ? `0${sum}` : `${sum}`;

      if (sum !== x.join("")) return false;
      return true;
    },
    next() {
      this.answers.push(this.answer);
      const val =
        this.currentStep.includes("input") || this.answer.value ? "yes" : "no";
      const nextStep = this.steps[this.currentStep][val];
      this.activeSteps.push(nextStep);

      console.log(this.answer, nextStep);

      if (nextStep === "final_step_company_input_people") {
        this.answer = {
          representatives: [{ ...this.userDetails }],
          artists: [
            { ...this.allAnswers.artist_input, me: this.allAnswers.is_artist }
          ]
        };
      } else {
        this.answer = {};
      }
    },
    previous() {
      this.activeSteps.pop();
      this.answer = this.answers[this.answers.length - 1];
      this.answers.pop();
    },
    openAddArtistModal() {
      this.answer.artists.push({});
      this.addingArtist = true;
    },
    closeAddArtistModal(val) {
      if (val === "cancelled") this.answer.artists.pop();
      this.addingArtist = false;
    },
    openAddRepresentativeModal() {
      this.answer.representatives.push({});
      this.addingRepresentative = true;
    },
    closeAddRepresentative(val) {
      if (val === "cancelled") this.answer.representatives.pop();
      this.addingRepresentative = false;
    },
    deleteArtist(index) {
      this.answer.artists.splice(index, 1);
    },
    deleteRepresentative(index) {
      this.answer.representatives.splice(index, 1);
    },
    closeAddCard(card) {
      if (card) this.card = card;
      else this.plan = "";
      this.addingCard = false;
      if (this.stepValid) this.submit();
    },
    formatRepresentatives(isMyArtist, representatives, company) {
      const user_id = this.$store.getters["account/getUserId"];

      return representatives.flatMap(({ firstName, lastName, email, me }) => {
        let resp = {
          user_id_inviter: user_id,
          role: "representative",
          access: "owner"
        };

        resp.user = {
          data: {
            first_name: firstName,
            last_name: lastName,
            display_name: `${firstName} ${lastName}`,
            email,
            status: "invited",
            company_users: {
              data: {
                role: "representative",
                company: {
                  data: { name: company.name, address: company.address },
                  on_conflict: {
                    update_columns: ["name"],
                    constraint: "companies_name_key"
                  }
                }
              },
              on_conflict: {
                update_columns: ["role"],
                constraint: "company_users_company_id_user_id_key"
              }
            },
            onboarding_complete: true
          },
          on_conflict: {
            update_columns: ["email"],
            constraint: "users_email_key",
            where: { email: { _eq: email } }
          }
        };

        if (me) resp.user.data.id = user_id;

        return isMyArtist && me ? [] : [resp];
      });
    },
    formatArtists(
      artists,
      representatives,
      company,
      recording_agreement,
      recording_agreement_third_party
    ) {
      const user_id = this.$store.getters["account/getUserId"];

      return artists.map(el => {
        let artistUser = {
          user_id_inviter: user_id,
          role: "artist",
          access: "owner"
        };

        if (el.me?.value)
          artistUser.user = {
            data: {
              id: user_id,
              email: "",
              address: el.address
            },
            on_conflict: {
              constraint: "users_pkey",
              update_columns: ["address"]
            }
          };
        else {
          artistUser.user = {
            data: {
              first_name: el.firstName,
              last_name: el.lastName,
              email: el.email,
              display_name: `${el.firstName} ${el.lastName}`,
              status: "invited",
              address: el.address,
              onboarding_complete: true
            },
            on_conflict: {
              update_columns: ["email"],
              constraint: "users_email_key",
              where: { email: { _eq: el.email } }
            }
          };
        }

        const reps = representatives.length
          ? this.formatRepresentatives(el.me?.value, representatives, company)
          : [];

        return {
          name: el.talentName,
          pro: el.PROAffiliation,
          ipi_songwriter: el.songwriterIPINo,
          ipi_publisher: el.publisherIPINo,
          publisher_name: el.publishingAdmin,
          address: el.address,
          artist_users: {
            data: [artistUser, ...reps]
          },
          artist_preferences: { data: {} },
          company_artists: !company
            ? null
            : {
              data: {
                recording_agreement,
                recording_agreement_third_party,
                company: {
                  data: { name: company.name, address: company.address },
                  on_conflict: {
                    update_columns: ["name"],
                    constraint: "companies_name_key"
                  }
                }
              }
            }
        };
      });
    },
    async onboardUser(user_id, artists) {
      const {
        data: { update_users_by_pk, insert_artists }
      } = await this.$apollo.mutate({
        mutation: ONBOARDING,
        variables: { user_id, artists }
      });

      if (
        !update_users_by_pk.onboarding_complete ||
        !insert_artists.returning.length
      ) {
        throw new Error("Onboarding failed");
      }

      this.$store.commit("account/updateArtist", insert_artists.returning[0]);

      return insert_artists.returning.map(({ id }) => id);
    },
    async addConnections() {
      const user = this.$store.getters["account/getUser"];

      const {
        data: { success }
      } = await this.$apollo.mutate({
        mutation: SYNC_CONTACTS,
        variables: { email: user.email }
      });
      console.log(success);

      // if (!insert_artist_connections.affected_rows) {
      //   throw new Error("Could not add connections");
      // }
    },
    async activateSubscription(artist_ids) {
      const {
        data: { stripe_subscribe }
      } = await this.$apollo.mutate({
        mutation: SUBSCRIBE,
        variables: { artist_ids }
      });

      if (!stripe_subscribe.id) {
        throw new Error("Could not activate the subscription");
      }
    },
    async submit() {
      try {
        this.submitting = true;

        const {
          final_step_artist_input_independent,
          company_input_profile,
          final_step_company_input_people,
          recording_agreement,
          recording_agreement_third_party
        } = this.allAnswers;

        let altArtist = {
          PROAffiliation: this.answer.PROAffiliation,
          address: this.answer.address,
          songwriterIPINo: this.answer.songwriterIPINo,
          talentName: this.answer.talentName,
          publishingAdmin: this.answer.publishingAdmin,
          publisherIPINo: this.answer.publisherIPINo,
          me: this.allAnswers.is_artist
        };

        const allArtists = !company_input_profile
          ? [
            {
              ...altArtist,
              ...final_step_artist_input_independent,
              me: { value: true }
            }
          ]
          : final_step_company_input_people?.artists;

        const allRepresentatives = !company_input_profile
          ? []
          : final_step_company_input_people.representatives;

        console.log(allArtists);

        const artists = this.formatArtists(
          allArtists,
          allRepresentatives,
          company_input_profile,
          !!recording_agreement,
          !!recording_agreement_third_party
        );

        const user_id = this.$store.getters["account/getUserId"];
        await this.onboardUser(user_id, artists);

        // const [from, ...rest] = artistIds;
        // const connections = rest.map((to) => ({
        //   artist_id_from: from,
        //   artist_id_to: to,
        // }));

        await this.addConnections();

        this.$store.commit("account/onboardingCompleted");
        this.$router.push({ name: "Catalog" });
      } catch (e) {
        console.log(e.message);
        alert(e.message || e);
      } finally {
        this.submitting = false;
      }
    },
    logout() {
      this.$store.commit("account/logout");
      window.location.href = "/";
    }
  },
  mounted() {
    if (!this.isNvoko) {
      this.answers.push({
        value: true
      });

      this.activeSteps.push("is_represented");

      this.answers.push({
        value: false
      });

      this.activeSteps.push("recording_agreement_label");

      this.answers.push({
        value: false
      });

      this.activeSteps.push("final_step_artist_input_independent");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #181818;
  margin: 0;
}

.modal-header {
  padding: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .logo {
    width: 55px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $white;
      margin-right: 0.7rem;
      margin-bottom: 6px;
    }

    div {
      margin-top: 5px;
    }
  }
}

.modal-body {
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .progressbar {
    height: 10px;
    min-width: 20rem;
    margin: 1rem 0 2rem;
    background: $text;
    border-radius: 10px;

    .line {
      height: 10px;
      background: $accent;
      border-radius: 10px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 275px - 3rem);
    overflow-y: auto;

    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }

    .step-1,
    .step-2,
    .step-3,
    .step-4,
    .step-5 {
      height: 100%;
    }

    .step-1,
    .step-2,
    .step-5 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        width: 20rem;
        color: $white;
        margin: 0 0 3rem;
        text-align: center;
      }

      .radiobuttons {
        margin-bottom: 2rem;

        .text {
          font-weight: 500;
        }

        .sub {
          font-size: 0.8rem;
          line-height: 0.9rem;
          margin: 8px 5px;
        }
      }
    }

    .step-4,
    .step-5 {
      height: 100%;

      .inputs {
        min-width: 20rem;
        margin-bottom: 32px;

        .form-group {
          margin-bottom: 24px;

          input {
            @include details-light();
            color: $white;
          }

          span {
            font-weight: 500;
            line-height: 17px;
            letter-spacing: -0.22px;
            color: #fcfffc;
          }
        }
      }

      h4 {
        color: $white;
        margin-bottom: 1rem;
      }

      .people {
        color: $white;
        margin-bottom: 3rem;

        .row {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 38px;
            width: 38px;
            font-family: sans-serif;
            font-size: 15px;
            border-radius: 50%;
            background-color: $accent;
            color: $white;
            margin-right: 1rem;
          }

          .delete {
            margin-left: auto;
            cursor: pointer;

            &:hover {
              color: $accent;
            }
          }

          .add-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 38px;
            width: 38px;
            font-family: sans-serif;
            font-size: 20px;
            border-radius: 50%;
            border: 1px $accent dashed;
            background-color: transparent;
            color: $accent;
            margin-right: 1rem;

            &:hover {
              cursor: pointer;
              background: rgba($text, 0.3);
            }

            &:focus {
              outline: none;
            }
          }
        }

        .add {
          width: fit-content;

          &:hover {
            color: $accent;
            cursor: pointer;
          }
        }
      }
    }

    .step-4 {
      padding-top: 30px;
    }

    .step-6 {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .title {
        color: $white;
        font-size: 20px;
        margin-bottom: 3rem;
      }

      .plans {
        display: flex;
        width: 65%;
        justify-content: space-between;

        .plan {
          width: 47%;
          cursor: pointer;
          position: relative;
          padding: 20px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 25px;
          background: linear-gradient(210.04deg,
              rgba(255, 255, 255, 0.07) 12.89%,
              rgba(255, 255, 255, 0) 81.68%),
            #141414;
          box-sizing: border-box;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 25px;
          border: 2px solid #ccc;
          display: flex;
          flex-direction: column;

          p {
            color: $white;
            margin-top: 16px;
          }

          strong {
            color: $accent;
          }

          .medium {
            font-size: 20px;
            margin-top: 10px;
          }

          /*&:hover,*/
          &.active {
            border: 2px solid #ff035a;
          }

          h2 {
            color: $white;
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
          }

          .price {
            color: $white;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 24px;
          }

          .description {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 40px;
          }
        }
      }
    }

    .step-7 {
      display: flex;
      justify-content: center;

      p {
        margin: 2rem;
        width: 75%;
        font-size: 1.3rem;
        text-align: center;
        color: $white;
      }
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  padding: 50px 0 50px;

  button {
    width: 136px;
    height: 44px;
    margin: 0 16px;
    font-weight: 300;
  }
}
</style>
